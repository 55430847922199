exports.components = {
  "component---src-components-project-template-project-template-tsx": () => import("./../../../src/components/ProjectTemplate/ProjectTemplate.tsx" /* webpackChunkName: "component---src-components-project-template-project-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cause-nobody-asked-tsx": () => import("./../../../src/pages/cause-nobody-asked.tsx" /* webpackChunkName: "component---src-pages-cause-nobody-asked-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cv-tsx": () => import("./../../../src/pages/cv.tsx" /* webpackChunkName: "component---src-pages-cv-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */)
}

