//* Dependencies
import i18n from "i18next";
import React, { createContext, useEffect, useState } from "react";
import { I18nextProvider, initReactI18next } from "react-i18next";
import dayjs from "dayjs";
import 'dayjs/locale/de';
import 'dayjs/locale/en';

//* Types
export type SupportedThemes = "dark" | "light"
export type SupportedLanguages = "de" | "en"

export type AppContextProps = {
    colorScheme: SupportedThemes,
    toggleColorScheme: () => void,
    language: SupportedLanguages,
    toggleLanguage: () => void
}

//* Interfaces
interface AppContextProviderProps {
    children: React.ReactNode
}

//* Translations
import deTranslation from "../../../../locales/de/translation.json";
import enTranslation from "../../../../locales/en/translation.json";

//* i18next Configuration
i18n
    .use(initReactI18next)
    .init({
        resources: {
            de: {
                translation: deTranslation
            },
            en: {
                translation: enTranslation
            }
        },
        lng: 'de',
        fallbackLng: 'de',
        interpolation: {
            escapeValue: false
        }
    })

//* Initializing language for dayjs
dayjs.locale('de');


//* Context
export const AppContext = createContext<AppContextProps>(null!);


//* Provider
export default function AppContextProvider({ children }: AppContextProviderProps) {

    //* Variables
    const [colorScheme, setColorScheme] = useState<SupportedThemes>("dark");
    const [language, setLanguage] = useState<SupportedLanguages>("de");

    //* Checking for selected color scheme and language
    useEffect(() => {
        const storedColorScheme = localStorage.getItem('colorScheme');
        const storedLanguage = localStorage.getItem('language');

        if (storedColorScheme) {
            setColorScheme(storedColorScheme as SupportedThemes);
        }

        if (storedLanguage) {
            setLanguage(storedLanguage as SupportedLanguages);
            i18n.changeLanguage(storedLanguage);
            dayjs.locale(storedLanguage);
        }
    }, []);

    //* Toggling color scheme
    function toggleColorScheme() {
        const newColorScheme = colorScheme === "dark" ? "light" : "dark";
        setColorScheme(newColorScheme);
        localStorage.setItem('colorScheme', newColorScheme);
    }

    //* Toggling display language
    function toggleLanguage() {
        const newLanguage = language === "de" ? "en" : "de";
        setLanguage(newLanguage);
        localStorage.setItem('language', newLanguage);
        i18n.changeLanguage(newLanguage);
        dayjs.locale(newLanguage);
    }

    //* Current context value
    const contextValue: AppContextProps = {
        colorScheme,
        toggleColorScheme,
        language,
        toggleLanguage
    }

    return (
        <AppContext.Provider value={contextValue}>
            <I18nextProvider i18n={i18n}>
                { children }
            </I18nextProvider>
        </AppContext.Provider>
    )

}